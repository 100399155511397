import $ from 'jquery'
import _ from 'underscore'

function explode(text, max) {
    // clean the text
    text = text.replace(/  +/g, " ").replace(/^ /, "").replace(/ $/, "");
    // return empty string if text is undefined
    if (typeof text === "undefined") return "";
    // if max hasn't been defined, max = 50
    if (typeof max === "undefined") max = 28;
    // return the initial text if already less than max
    if (text.length <= max) return text;
    // get the first part of the text
    var exploded = text.substring(0, max);
    // get the next part of the text
    text = text.substring(max);
    // if next part doesn't start with a space
    if (text.charAt(0) !== " ") {
        // while the first part doesn't end with a space && the first part still has at least one char
        while (exploded.charAt(exploded.length - 1) !== " " && exploded.length > 0) {
            // add the last char of the first part at the beginning of the next part
            text = exploded.charAt(exploded.length - 1) + text;
            // remove the last char of the first part
            exploded = exploded.substring(0, exploded.length - 1);
        }
        // if the first part has been emptied (case of a text bigger than max without any space)
        if (exploded.length === 0) {
            // re-explode the text without caring about spaces
            exploded = text.substring(0, max);
            text = text.substring(max);
            // if the first part isn't empty
        } else {
            // remove the last char of the first part, because it's a space
            exploded = exploded.substring(0, exploded.length - 1);
        }
        // if the next part starts with a space
    } else {
        // remove the first char of the next part
        text = text.substring(1);
    }
    // return the first part and the exploded next part, concatenated by \n
    return exploded + "\n" + explode(text);
}

export function loadSimpsons() {
    $('#photoHolder').html('')
    let settings = {
        "url":process.env.appurl + '/api/easteregg',
        "method": "GET",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded/",
            "Authorization": "Bearer " + localStorage.getItem('access_token')
        }
    }
    $.ajax(settings).done(function (response) {
        let url = encodeURI(response.url)
        $('#photoHolder').html('<div id="simpsonsMeme" style="max-height: 400px;max-width: 400px"><img src="' + url + '" alt=""></div>');
    });
}

export function loadParksAndRecEasterEgg(){
    $('#photoHolder').html('')
    let links = ["https://media.giphy.com/media/m9Lzkhf0YsRgI/giphy.gif",
        "https://media.giphy.com/media/zyin7TYoGmLAs/giphy.gif",
        "https://media.giphy.com/media/MofD6FusyLKzktNYPp/giphy.gif",
        "https://media.giphy.com/media/E90GssJoIqMRW/giphy.gif",
        "https://media.giphy.com/media/xUPGclJ5wKOJmENxcs/giphy.gif",
        "https://media.giphy.com/media/10QnBCa9QbQHiE/giphy.gif",
        "https://media.giphy.com/media/jKcZoEyfReNYQ/giphy.gif",
        "https://media.giphy.com/media/xhN4C2vEuapCo/giphy.gif",
        "https://media.giphy.com/media/yRAAHNTBGegNi/giphy.gif",
        "https://media.giphy.com/media/xTZGC0vnEmNDq/giphy.gif",
        "https://media.giphy.com/media/10ABvIA9IM29tC/giphy.gif",
        "https://media.giphy.com/media/i1hiQy3uVZ0KQ/giphy.gif",
        "https://media.giphy.com/media/Fy3HFZdPyYAo/giphy.gif",
        "https://media.giphy.com/media/JujGDzC62NZxS/giphy.gif",
        "https://media.giphy.com/media/xTiTnLWl6ftNuZAe8E/giphy.gif",
        "https://media.giphy.com/media/3Yw3MYQXeIy1q/giphy.gif",
        "https://media.giphy.com/media/mnnJUiakh7qrS/giphy.gif",
        "https://media.giphy.com/media/12sGthh8OsSxOg/giphy.gif",
        "https://media.giphy.com/media/12y6J4xHcmcKTS/giphy.gif",
        "https://media.giphy.com/media/8s1bWO7hckdW/giphy.gif",
        "https://media.giphy.com/media/lAVZ985B1K2nC/giphy.gif",
        "https://media.giphy.com/media/aAv3oNw804Rwc/giphy.gif",
        "https://media.giphy.com/media/18QoMAJvZkkJW/giphy.gif",
        "https://giphy.com/gifs/vc0KiL9PrHzLMZpjyh/giphy.gif",
        "https://media.giphy.com/media/GbQCQTMm9uuWs/giphy.gif",
        "https://media.giphy.com/media/jlbYyYfKpwRDG/giphy.gif",
        "https://media.giphy.com/media/dNF1565ln6sE/giphy.gif",
        "https://media.giphy.com/media/10l8MVei2OxbuU/giphy.gif",
        "https://media.giphy.com/media/LrBIHFnVx5xqE/giphy.gif",
        "https://media.giphy.com/media/gjBVH08sTJNHP4bRAo/giphy.gif",
        "https://media.giphy.com/media/LhrCVa5S5ZwUhQUshi/giphy.gif",
        "https://media.giphy.com/media/8tLQQ0VyjOK6Q/giphy.gif",
        "https://media.giphy.com/media/LqsOl7uaP5v8azhLRI/giphy.gif",
        "https://media.giphy.com/media/KY1QhQQ4PXMczIX350/giphy.gif",
        "https://media.giphy.com/media/xUA7bdAqaOEl6omLks/giphy.gif",
        "https://media.giphy.com/media/NDIiWKEQEgr3VA7aqM/giphy.gif",
        "https://media.giphy.com/media/Mbduc7M3yxkLm/giphy.gif",
        "https://media.giphy.com/media/BkstFTXcQ70ih9Z9SF/giphy.gif",
        "https://media.giphy.com/media/NylYRvfyaIwtW/giphy.gif",
        "https://media.giphy.com/media/IdmfEtnMWPzOg/giphy.gif",
        "https://media.giphy.com/media/hR0RAwAIubzxsrrCxW/giphy.gif",
        "https://media.giphy.com/media/kHU8W94VS329y/giphy.gif",
        "https://media.giphy.com/media/E6oA28KbDfcmfFWU2j/giphy.gif",
        "https://media.giphy.com/media/B6lEtb1dgtUoo/giphy.gif",
        "https://media.giphy.com/media/trOz6pzrVzTUY/giphy.gif",
        "https://media.giphy.com/media/qZiUOutzxgfKM/giphy.gif",
        "https://media.giphy.com/media/mO0YX04Vk7Kqk/giphy.gif",
        "https://media.giphy.com/media/TPwnbfbbsMvL2/giphy.gif",
        "https://media.giphy.com/media/XwtIKC8Kp8lO0/giphy.gif",
        "https://media.giphy.com/media/qeikFgYSIF09q/giphy.gif",
        "https://media.giphy.com/media/Xej3GbOrGgWY4U4Rwi/giphy.gif",
        "https://media.giphy.com/media/WbUR5ubgWdHdS/giphy.gif",
        "https://media.giphy.com/media/3o6ZthnDqxfAOFIhdC/giphy.gif",
        "https://media.giphy.com/media/oTQivNTfgzKFi/giphy.gif",
        "https://media.giphy.com/media/A413bgMWn5ew/giphy.gif",
        "https://media.giphy.com/media/elgP9Ip0KvHB0rmyXq/giphy.gif",
        "https://media.giphy.com/media/UVAxgBmuDUJTJJPs58/giphy.gif",
        "https://media.giphy.com/media/3MuYugq6KUPT2/giphy.gif",
        "https://media.giphy.com/media/ovW7ypiJI8Ypy/giphy.gif",
        "https://media.giphy.com/media/QM49J611TbWAMXtycR/giphy.gif",
        "https://media.giphy.com/media/3ohhwuceyvTVjnzHDG/giphy.gif",
        "https://media.giphy.com/media/qPtb4C4Mv2Ylq/giphy.gif",
        "https://media.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif",
        "https://media.giphy.com/media/dEdmW17JnZhiU/giphy.gif",
        "https://media.giphy.com/media/Qa5dsjQjlCqOY/giphy.gif",
        "https://media.giphy.com/media/jfRGnN954yU7u/giphy.gif",
        "https://media.giphy.com/media/82Ild1nsVQgkU/giphy.gif",
        "https://media.giphy.com/media/VbtnUFEuUnKsz1qCWF/giphy.gif",
        "https://media.giphy.com/media/sKF481S11DIYg/giphy.gif",
        "https://media.giphy.com/media/RCU62OxvGy4ZW/giphy.gif",
        "https://media.giphy.com/media/PjBejIzN9BwsL8uQ0F/giphy.gif",
        "https://media.giphy.com/media/LPUAXWb8Qqp2G23vnc/giphy.gif",
        "https://media.giphy.com/media/WQwzX2fGgu7LQuuGZV/giphy.gif",
        "https://media.giphy.com/media/xT9DPzgJqzHkSgh572/giphy.gif",
        "https://media.giphy.com/media/21MomXcvfjK1qd8OKn/giphy.gif",
        "https://media.giphy.com/media/3o7budjt2L4nx185qM/giphy.gif",
        "https://media.giphy.com/media/3euyZDWyftvoIZIRcH/giphy.gif",
        "https://media.giphy.com/media/VgfGPXUVuX6tWov6Kj/giphy.gif",
        "https://media.giphy.com/media/VgfGPXUVuX6tWov6Kj/giphy.gif",
        "https://media.giphy.com/media/Us4HPK63xHAoHTKbHT/giphy.gif",
        "https://media.giphy.com/media/l41lLs970IkkBi6f6/giphy.gif",
        "https://media.giphy.com/media/ofrnsIuGVpW4o/giphy.gif",
        "https://media.giphy.com/media/C767ptzM7gdUc/giphy.gif",
        "https://media.giphy.com/media/126NMCvhKJfNN6/giphy.gif",
        "https://media.giphy.com/media/x14pCkPEB4H9S/giphy.gif",
        "https://media.giphy.com/media/xXjIVvfvkvqZG/giphy.gif",
        "https://media.giphy.com/media/z9m4eqEvBxxBu/giphy.gif",
        "https://media.giphy.com/media/4cuyucPeVWbNS/giphy.gif",
        "https://media.giphy.com/media/14gWV2vqzwobcc/giphy.gif",
        "https://media.giphy.com/media/fZyifXy4F6OkUeZij3/giphy.gif",
    ]
    let url = _.sample(links)
    $('#photoHolder').html('<div id="parksGif" style="max-height: 400px;max-width: 400px"><img src="' + url + '" alt=""></div>');
}

export function loadDog(){
    $('#photoHolder').html('')
    $.ajax('https://dog.ceo/api/breeds/image/random').done(function (response){
        $('#photoHolder').html('<div id="dogPic" style="max-height: 400px;max-width: 400px"><img src="' + response['message'] + '" alt=""></div>');
    })
}

export function loadCat(){
    $('#photoHolder').html('')
    $.ajax('https://api.thecatapi.com/v1/images/search').done(function (response){
        $('#photoHolder').html('<div id="catPic" style="max-height: 400px;max-width: 400px"><img src="' + response[0]['url'] + '" alt=""></div>');
    })
}

export function prepEasterEgg(){
    $('#buttonHeader').html(
        '<button id="loadSimp" type="button" class="btn btn-primary btn-sm">Load Simpsons Meme</button>&nbsp&nbsp\n' +
        '<div class="btn-group">\n' +
        '    <button id="loadPR" type="button" class="btn btn-success btn-sm">Load GIF</button>\n' +
        // '    <button type="button" class="btn btn-success btn-sm dropdown-toggle dropdown-toggle-split"\n' +
        // '            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">\n' +
        // '    </button>\n' +
        // '    &nbsp&nbsp\n' +
        // '    <div id="gifdrop" class="dropdown-menu">\n' +
        // '        <form class="px-4 py-3">\n' +
        // '            <div class="form-group">\n' +
        // '                <label for="gifSubmit">Add Your Own. Submit a link to a .gif file</label>\n' +
        // '                <input type="text" class="form-control" id="gifSubmit" placeholder="http://example.com/example.gif">\n' +
        // '            </div>\n' +
        // '            <button type="button" class="btn btn-success btn-sm">Submit</button>\n' +
        // '        </form>\n' +
        // '    </div>\n' +
        '</div>\n' +
        '<button id="loadDog" type="button" class="btn btn-secondary btn-sm">Load Dog Pic</button>\n' +
        '&nbsp&nbsp\n' +
        '<button id="loadCat" type="button" class="btn btn-warning btn-sm">Load Cat Pic</button>\n' +
        '&nbsp&nbsp Click any to re-load<br>'
    )
    $('#loadSimp').on('click', function (){
        loadSimpsons()
    })
    $('#loadPR').on('click', function (){
        loadParksAndRecEasterEgg()
    })
    $('#loadDog').on('click',function (){
        loadDog()
    })
    $('#loadCat').on('click',function (){
        loadCat()
    })

}



