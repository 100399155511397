import {TabulatorFull as Tabulator} from "tabulator-tables";
import {
    classificationParamBuilder,
    constructionParamBuilder,
    framemediaParamBuilder,
    illTypeParamBuilder,
    mediaParamBuilder,
    statusParamBuilder,
    tfParams
} from "../infoDisplays/tableParamBuilders";
import {
    classificationTypeVals,
    constructionTypeVals,
    illuminationTypeVals,
    mediaTypeVals,
    placeTypeVals,
    statusTypeVals
} from "../supportFunctions/apiTypes";

export let inventoryManagementTabulator

export function createInventoryManagementTabulator(data) {
    inventoryManagementTabulator = new Tabulator("#inventoryManagementTable", {
            // selectable: 1,
            data: data,
            layout: "fitData",
            height: "75vh",
            groupBy: "frame_id",
            pagination: "local",
            clipboard: true,
            clipboardCopyStyled: false,
            clipboardCopyRowRange: "visible",
            clipboardCopyConfig: {
                columnGroups: false, //do not include column groups in column headers for printed table
                rowGroups: false, //do not include row groups in clipboard output
                columnCalcs: false, //do not include column calculation rows in clipboard output
                dataTree: false, //do not include data tree in printed table
            },
            columns: [
                {
                    title: "Frame ID",
                    field: "frame_id",
                    frozen: true,
                    sorter: "number",
                    headerFilter: true
                },
                {
                    title: "Spot ID",
                    field: "spot_id",
                    sorter: "number",
                    frozen: true,
                    headerFilter: true
                },
                {
                    title: "Operator Frame ID",
                    field: "plant_frame_id",
                    sorter: "string",
                    headerFilter: true,
                    editor: true,
                },
                {
                    title: "Operator Spot ID",
                    field: "plant_spot_id",
                    sorter: "string",
                    headerFilter: true,
                    editor: true,
                },
                {
                    title: "Construction Name",
                    field: "construction_name",
                    sorter: "number",
                    headerFilter: true,
                    editor: true,
                },
                {
                    title: "Construction Type Name",
                    field: "construction_type_name",
                    sorter: "string",
                    headerFilter: "select",
                    headerFilterParams: constructionParamBuilder,
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: constructionTypeVals

                },
                {
                    title: "Media Type Name",
                    field: "media_type_name",
                    sorter: "string",
                    headerFilter: "select",
                    headerFilterParams: mediaParamBuilder,
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: mediaTypeVals
                },
                {
                    title: "Frame Media Name",
                    field: "frame_media_name",
                    sorter: "string",
                    headerFilter: "select",
                    headerFilterParams: framemediaParamBuilder,
                    headerFilterFunc: "=",
                    editor: true,
                },
                {
                    title: "Frame Digital",
                    field: "frame_digital",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Status Type Name",
                    field: "status_type_name",
                    sorter: "string",
                    headerFilter: "select",
                    headerFilterParams: statusParamBuilder,
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: statusTypeVals
                },
                {
                    title: "Classification Type Name",
                    field: "classification_type_name",
                    sorter: "string",
                    headerFilter: "select",
                    headerFilterParams: classificationParamBuilder,
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: classificationTypeVals
                },
                {
                    title: "Illumination Start Time",
                    field: "illumination_start_time",
                    sorter: "string",
                    editor: true,
                },
                {
                    title: "Illumination End Time",
                    field: "illumination_end_time",
                    sorter: "string",
                    editor: true,
                },
                {
                    title: "Illumination Type Name",
                    field: "illumination_type_name",
                    sorter: "string",
                    headerFilter: "select",
                    headerFilterParams: illTypeParamBuilder,
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: illuminationTypeVals
                },
                {
                    title: "Frame Max Height",
                    field: "frame_max_height",
                    sorter: "number",
                    editor: true,
                },
                {
                    title: "Frame Max Width",
                    field: "frame_max_width",
                    sorter: "number",
                    editor: true,
                },
                {
                    title: "Frame Max Pixel Height",
                    field: "frame_max_pixel_height",
                    sorter: "string",
                    editor: true,
                },
                {
                    title: "Frame Max Pixel Width",
                    field: "frame_max_pixel_width",
                    sorter: "string",
                    editor: true,
                },
                {
                    title: "Construction Date",
                    field: "construction_date",
                    sorter: "string",
                    editor: true,
                },
                {
                    title: "Frame Description",
                    field: "frame_description",
                    sorter: "string",
                    editor: true,
                },
                {
                    title: "Frame Full Motion",
                    field: "frame_full_motion",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: tfParams,
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Frame Partial Motion",
                    field: "frame_partial_motion",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Frame Rotating",
                    field: "frame_rotating",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Frame Interactive",
                    field: "frame_interactive",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Frame Audio",
                    field: "frame_audio",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Engagement",
                    field: "engagement",
                    sorter: "number"
                },
                {
                    title: "Notes",
                    field: "notes",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Created By",
                    field: "created_by",
                    sorter: "string"
                },
                {
                    title: "Created Dtm",
                    field: "created_dtm",
                    sorter: "string"
                },
                {
                    title: "Updated By",
                    field: "updated_by",
                    sorter: "string"
                },
                {
                    title: "Updated Dtm",
                    field: "updated_dtm",
                    sorter: "string"
                },
                {
                    title: "Updated Desc",
                    field: "updated_desc",
                    sorter: "string"
                },
                {
                    title: "Orientation",
                    field: "orientation",
                    sorter: "number",
                    editor: true
                },
                {
                    title: "Primary Read",
                    field: "primary_read",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Primary Artery",
                    field: "primary_artery",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Level",
                    field: "level",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Levels Visible",
                    field: "levels_visible",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Latitude",
                    field: "latitude",
                    sorter: "number",
                    editor: true
                },
                {
                    title: "Longitude",
                    field: "longitude",
                    sorter: "number",
                    editor: true
                },
                {
                    title: "Block Id",
                    field: "block_id",
                    sorter: "number"
                },
                {
                    title: "Elevation",
                    field: "elevation",
                    sorter: "string"
                },
                {
                    title: "Dma Id",
                    field: "dma_id",
                    sorter: "number"
                },
                {
                    title: "Zip Code",
                    field: "zip_code",
                    sorter: "number"
                },
                {
                    title: "Time Zone",
                    field: "time_zone",
                    sorter: "number"
                },
                {
                    title: "Place Type Name",
                    field: "place_type_name",
                    sorter: "number",
                    editor: "select",
                    editorParams: placeTypeVals

                },
                {
                    title: "Operator Account",
                    field: "account_name",
                    sorter: "number"
                },
                {
                    title: "Operator Account code",
                    field: "account_operator_code",
                    sorter: "string"
                },
                {
                    title: "Layout ID",
                    field: "layout_id",
                    sorter: "number"
                },
                {
                    title: "Layout Share of Voice",
                    field: "layout_sov",
                    sorter: "number"
                },
                {
                    title: "Layout Full Motion",
                    field: "layout_full_motion",
                    sorter: "boolean"
                },
                {
                    title: "Layout Partial Motion",
                    field: "layout_partial_motion",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Layout Rotating",
                    field: "layout_rotating",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Layout Interactive",
                    field: "layout_interactive",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Layout Audio",
                    field: "layout_audio",
                    sorter: "boolean",
                    headerFilter: "select",
                    headerFilterParams: {values: {"true": "true", "false": "false"}},
                    headerFilterFunc: "=",
                    editor: "select",
                    editorParams: tfParams()
                },
                {
                    title: "Face ID",
                    field: "face_id",
                    sorter: "number"
                },
                {
                    title: "Face Height",
                    field: "face_height",
                    sorter: "number",
                    editor: true
                },
                {
                    title: "Face Width",
                    field: "face_width",
                    sorter: "number",
                    editor: true
                },
                {
                    title: "Face Pixel Height",
                    field: "face_pixel_height",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Face Pixel Width",
                    field: "face_pixel_width",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Avg Spot Length",
                    field: "avg_spot_length",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Spots In Rotation",
                    field: "spots_in_rotation",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Publisher Unique Face Id",
                    field: "publisher_unique_face_id",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Top Left Pixel X",
                    field: "top_left_pixel_x",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Top Left Pixel Y",
                    field: "top_left_pixel_y",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Publisher Unique Id",
                    field: "publisher_unique_id",
                    sorter: "string",
                    editor: true
                },
                {
                    title: "Spot Sov",
                    field: "spot_sov",
                    sorter: "number"
                }

            ],
            footerElement: '<span id="frameTotal"></span>&nbsp&nbsp&nbsp&nbsp<span id="spotTotal"></span>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp',
            dataLoaded: function () {
                this.getColumn("frame_media_name").reloadHeaderFilter();
                this.getColumn("status_type_name").reloadHeaderFilter();
                this.getColumn("classification_type_name").reloadHeaderFilter();
                this.getColumn("illumination_type_name").reloadHeaderFilter();
                this.getColumn("media_type_name").reloadHeaderFilter();
                this.getColumn("construction_type_name").reloadHeaderFilter();

            },
            dataFiltered: function () {
                this.getColumn("frame_media_name").reloadHeaderFilter();
                this.getColumn("status_type_name").reloadHeaderFilter();
                this.getColumn("classification_type_name").reloadHeaderFilter();
                this.getColumn("illumination_type_name").reloadHeaderFilter();
                this.getColumn("media_type_name").reloadHeaderFilter();
                this.getColumn("construction_type_name").reloadHeaderFilter();
            },
            // renderComplete: function () {
            //     getCellCounts()
            // },
            cellEdited: function (cell, formatterParams) {
                cell.getElement().style.backgroundColor = "#f00";
            }
        }
    )
}